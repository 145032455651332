import dynamic from 'next/dynamic';

export const LazyComponent = dynamic(() =>
  import('@/components/molecules/LazyComponent')
);
export const BrandCard = dynamic(() => import('./BrandCard'));
export const InfoCard = dynamic(() => import('./InfoCard'));
export const OptionBrandResult = dynamic(() => import('./OptionBrandResult'));
export const PageSpinner = dynamic(() => import('./PageSpinner'));
export const NavBar = dynamic(() => import('./NavBar'));
export const NavBarModal = dynamic(() => import('./NavBar/NavBarModal'));
export const NavbarSection = dynamic(() => import('./NavbarSection'));
export const Breadcrumb = dynamic(() => import('./Breadcrumb'));
export const PunctuationReview = dynamic(() => import('./PunctuationReview'));
export const Streaming = dynamic(() => import('./Streaming'));
export const OptionSubCategoryResult = dynamic(() =>
  import('./OptionSubCategoryResult')
);
export const LoginLogos = dynamic(() =>
  import('@/components/molecules/LoginLogos')
);

export const ProductGalleryCard = dynamic(() => import('./ProductGalleryCard'));
export const SuggestionItem = dynamic(() =>
  import('./SearchBar/SuggestionList/SuggestionItem')
);
export const SuggestionsList = dynamic(() =>
  import('./SearchBar/SuggestionList')
);
export const GeneralModal = dynamic(() => import('./GeneralModal'));
export const ProductSheetCard = dynamic(() => import('./ProductSheetCard'));

export const SearchBar = dynamic(() => import('./SearchBar'));
export const Chips = dynamic(() => import('./Chips'));
export const Spinner = dynamic(() => import('./Spinner'));
export const Title = dynamic(() => import('./Title'));
export const FaqSection = dynamic(() => import('./FaqSection'));
export const ValueCard = dynamic(() => import('./ValueCard'));
export const Slider = dynamic(() => import('@/components/molecules/Slider'));
export const ServicesBadge = dynamic(() =>
  import('@/components/molecules/ServicesBadge')
);
export const BenefitsRibbon = dynamic(() =>
  import('@/components/molecules/ProductSheetCard/ProductInfo/BenefitsRibbon')
);
export const ColorSelector = dynamic(() =>
  import('@/components/molecules/ProductSheetCard/ProductInfo/ColorSelector')
);
export const QuantitySelector = dynamic(() =>
  import('@/components/molecules/ProductSheetCard/ProductInfo/QuantitySelector')
);
export const SizeSelector = dynamic(() =>
  import('@/components/molecules/ProductSheetCard/ProductInfo/SizeSelector')
);

export const CPModal = dynamic(() => import('@/components/molecules/CPModal'));

export const AsistantChat = dynamic(() =>
  import('@/components/molecules/AsistantChat')
);
export const ChangeModalCart = dynamic(
  () => import('@/components/molecules/ChangeCartModal'),
  { ssr: false }
);
export const SelectMethodButton = dynamic(() =>
  import('@/components/molecules/SelectMethodButton')
);
